import Vue from 'vue'
import Router from 'vue-router'
import Application from "./view/pages/Application";
import Security from "./view/pages/Security";
import Dashboard from "./view/pages/application/Dashboard";
import Login from "./view/pages/security/Login";
import PasswordForget from "./view/pages/security/PasswordForget";
import PasswordReset from "./view/pages/security/PasswordReset";
import Logout from "./view/pages/security/Logout";
import SystemInformation from "./view/pages/application/SystemInformation";
import Users from "./view/pages/application/administration/Users";
import Profile from "./view/pages/application/Profile";
import Roles from "./view/pages/application/administration/Roles";
import Locations from "./view/pages/application/administration/Locations";
import Educations from "./view/pages/application/Educations";
import Courses from "./view/pages/application/Courses";
import Education from "./view/pages/application/Education";
import CourseDates from "./view/pages/application/CourseDates";
import Persons from "./view/pages/application/Persons";
import Course from "./view/pages/application/Course";
import Events from "./view/pages/application/Events";
import Event from "./view/pages/application/Event";
import Person from "./view/pages/application/Person";
import Touches from "./view/pages/application/Touches";
import Invoices from "./view/pages/application/Invoices";
import Documentation from "./view/pages/application/Documentation";
import UsersApiKey from "./view/pages/application/administration/UsersApiKey";
import CatalogueRequestExport from "./view/pages/application/tools/CatalogueRequestExport";
import Settings from "./view/pages/application/administration/Settings";
import PersonDuplicateResolver from "./view/pages/application/tools/PersonDuplicateResolver";
import ImportLogs from "./view/pages/application/administration/ImportLogs";
import ComplexTouch from "./view/pages/application/ComplexTouch";
import SupportedContractAppearance from './view/pages/application/reporting/SupportedContractAppearance.vue';
import ReportingOverview from "./view/pages/application/Reporting";
import Reporting from "./view/pages/application/reporting/Reporting";
import Reportings from "./view/pages/application/reporting/Reportings";
import CoursePerformance from "./view/pages/application/reporting/CoursePerformance";
import Acquisition from "./view/pages/application/reporting/Acquisition";
import Import from "./view/pages/application/tools/Import";
import Imports from "./view/pages/application/tools/Imports";
import TouchesConsultation from "./view/pages/application/TouchesConsultation";
import TemplateTouchCustoms from "./view/pages/application/administration/TemplateTouchCustoms";
import Callback from "./view/pages/application/Callback";
import ToolsCourse from "./view/pages/application/tools/Course";
import PersonBulk from "./view/pages/application/tools/PersonBulk";
import Portals from "./view/pages/application/tools/Portals";
import Portal from "./view/pages/application/tools/Portal";
import Reminders from "./view/pages/application/finance/Reminders";
import Earnings from "./view/pages/application/finance/Earnings";
import EarningImport from "./view/pages/application/finance/EarningImport";
import Analysis from "./view/pages/application/finance/Analysis";
import TimetableSuggest from "./view/pages/application/tools/TimetableSuggest";
import Reminder from "./view/pages/application/finance/Reminder";
import Rooms from "./view/pages/application/administration/Rooms";
import EventPlaning from "./view/pages/application/tools/EventPlaning";
import Holidays from "./view/pages/application/administration/Holidays";
import BulkEmail from "./view/pages/application/tools/BulkEmail.vue";
import SupportedCostCenterOverview from "./view/pages/application/crm/supportedCostCenter/index.vue";
import SupportedCostCenterDetail from "./view/pages/application/crm/supportedCostCenter/detail.vue";
import DocentOverview from "./view/pages/application/erp/docent/index.vue";
import DocentDetail from "./view/pages/application/erp/docent/detail.vue";
import CurriculaIndex from "@/pages/application/teaching/curricula/index.vue";
import CurriculaDetail from "@/pages/application/teaching/curricula/detail.vue";
import LocationOrganisation from '@/pages/application/tools/LocationOrganisation.vue';
import EventCourseApplication from '@/pages/application/erp/eventCourseApplication/index.vue';
import IntershipCompany from "@/pages/application/crm/intershipCompany/index.vue";
import Appearances from "@/pages/application/erp/Appearances.vue";
import DocentBilling from "@/pages/application/tools/DocentBilling.vue";

Vue.use(Router);

const routes = [
    {
        path: '/',
        name: 'Application',
        component: Application,
        children: [
            {
                path: '',
                redirect: to => "/persons"
            },
            {
                path: 'dashboard',
                component: Dashboard
            },
            {
                path: 'documentation',
                component: Documentation
            },
            {
                path: 'profile',
                component: Profile
            },
            {
                path: 'touchComplex',
                component: ComplexTouch,
            },
            {
                path: 'persons',
                component: Persons
            },
            {
                path: 'persons/:id',
                component: Person
            },
            {
                path: 'touches',
                component: Touches
            },
            {
                path: 'touches/consultation',
                component: TouchesConsultation
            },
            {
                path: 'educations',
                component: Educations
            },
            {
                path: 'educations/:id',
                component: Education
            },
            {
                path: 'courses',
                component: Courses
            },
            {
                path: 'courses/:id',
                component: Course
            },
            {
                path: 'courseDates',
                component: CourseDates
            },
            {
                path: 'events',
                component: Events
            },
            {
                path: 'events/:id',
                component: Event
            },
            {
                path: 'invoices',
                component: Invoices
            },
            {
                path: 'system-informationen',
                component: SystemInformation
            },
            {
                path: 'reporting',
                component: ReportingOverview,
            },
            {
                path: 'reporting/reporting/create',
                component: Reporting
            },
            {
                path: 'reporting/reporting/:id',
                component: Reporting
            },
            {
                path: 'reporting/reportings',
                component: Reportings
            },
            {
                path: 'reporting/coursePerformance',
                component: CoursePerformance
            },
            {
                path: 'reporting/acquisition',
                component: Acquisition
            },
            {
                path: 'reporting/supportedContractAppearance',
                component: SupportedContractAppearance,
            },
            {
                path: 'tools/docentBilling',
                component: DocentBilling
            },
            {
                path: 'tools/imports',
                component: Imports
            },
            {
                path: 'tools/bulkEmail',
                component: BulkEmail
            },
            {
                path: 'tools/import',
                component: Import
            },
            {
                path: 'tools/locationOrganisation',
                component: LocationOrganisation
            },
            {
                path: 'callbacks/:touchCallbackId/:action',
                component: Callback
            },
            {
                path: 'tools/catalogueRequestExport',
                component: CatalogueRequestExport
            },
            {
                path: 'tools/courseTools',
                component: ToolsCourse
            },
            {
                path: 'tools/personBulk',
                component: PersonBulk
            },
            {
                path: 'tools/personDuplicateResolver',
                component: PersonDuplicateResolver
            },
            {
                path: 'tools/portals',
                component: Portals
            },
            {
                path: 'tools/portals/:id',
                component: Portal
            },
            {
                path: 'tools/timetableSuggest',
                component: TimetableSuggest
            },
            {
                path: 'administration/template-custom-touch',
                component: TemplateTouchCustoms
            },
            {
                path: 'administration/holidays',
                component: Holidays
            },
            {
                path: 'tools/eventPlaning',
                component: EventPlaning
            },
            {
                path: 'administration/rooms',
                component: Rooms
            },
            {
                path: 'administration/users',
                component: Users
            },
            {
                path: 'administration/users-api',
                component: UsersApiKey
            },
            {
                path: 'administration/roles',
                component: Roles
            },
            {
                path: 'administration/locations',
                component: Locations
            },
            {
                path: 'administration/settings',
                component: Settings
            },
            {
                path: 'administration/importLogs',
                component: ImportLogs
            },
            {
                path: 'finance/reminders',
                component: Reminders
            },
            {
                path: 'finance/reminder',
                component: Reminder
            },
            {
                path: 'finance/earnings',
                component: Earnings
            },
            {
                path: 'finance/earningImport',
                component: EarningImport
            },
            {
                path: 'finance/analyses',
                component: Analysis
            },
            {
                path: 'crm/supportedCostCenters',
                component: SupportedCostCenterOverview
            },
            {
                path: 'crm/supportedCostCenters/:id',
                component: SupportedCostCenterDetail
            },
            {
                path: 'crm/intershipCompanies',
                component: IntershipCompany
            },
            {
                path: 'erp/docents',
                component: DocentOverview
            },
            {
                path: 'erp/docents/:id',
                component: DocentDetail
            },
            {
                path: 'erp/eventCourseApplications',
                component: EventCourseApplication,
            },
            {
                path: 'erp/appearances',
                component: Appearances,
            },
            {
                path: 'teaching/curricula',
                component: CurriculaIndex
            },
            {
                path: 'teaching/curricula/:id',
                component: CurriculaDetail
            }
        ]
    },
    {
        path: '/',
        name: 'Security',
        component: Security,
        children: [
            {
                name: 'login',
                path: '/login',
                component: Login
            },
            {
                name: 'logout',
                path: '/logout',
                component: Logout
            },
            {
                name: 'passwordForget',
                path: '/passwort-vergessen',
                component: PasswordForget
            },
            {
                name: 'passwordReset',
                path: '/passwort-zuruecksetzen/:passwordResetToken',
                component: PasswordReset
            }
        ]
    },
];


const router = new Router({
    mode: 'hash',
    routes: routes,
    linkExactActiveClass: 'active'
});

export default router;
