<template>
    <div>
        <input-text
                v-if="filter.type === 'text'"
                v-model.trim="value[filter.model]"
                :label="filter.label"
        />
        <label v-if="filter.type === 'bool'">
            {{filter.label}}
            <select
                    v-model="value[filter.model]"
            >
                <option value=""></option>
                <option value="1">Ja</option>
                <option value="0">Nein</option>
            </select>
        </label>

        <label v-if="filter.type === 'select'">
            {{filter.label}}
            <select
                    v-model="value[filter.model]"
            >
                <option :value="null" v-if="filter.disableNull === null || filter.disableNull !== true">-</option>
                <option v-for="label, option in filter.options" :value="option">{{label}}</option>
            </select>
        </label>

        <input-education-type-select
                v-if="filter.type === 'educationTypeSelect'"
                v-model="value[filter.model]"
                :label="filter.label"
        />

        <input-entity
                v-if="filter.type === 'entity'"
                :type="filter.entity"
                v-model="value[filter.model]"
                :label="filter.label"
        />

        <div v-if="filter.type === 'dateRange'">
            <input-date-range v-model="value[filter.model]" :label="filter.label"/>
        </div>

        <div v-if="filter.type === 'checkbox'">
            {{filter.label}}
            <br>
            <input type="checkbox" v-model="value[filter.model]">
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            filter: {
                type: Object | Boolean,
                required: true,
            },
            value: {
                type: Object,
                required: true,
            }
        },
        watch: {
            'value': {
                handler: function (newVal) {
                    this.$emit('input', newVal);
                },
                deep: true
            }
        }
    }
</script>
