<template>
    <div>
        <document-modal
            ref="documentModal"
        />

        <attendance-certification-generator-update-modal
            v-if="person.hasOwnProperty('@id')"
            :person="person"
            ref="attendanceCertificationGeneratorUpdateModal"
        />

        <student-id-update-modal
            v-if="person.hasOwnProperty('@id')"
            :person="person"
            ref="studentIdUpdateModal"
        />

        <document-creation-modal
            v-if="person.hasOwnProperty('@id')"
            :person="person"
            ref="supportedContractAppearanceDocumentCreationModal"
        />

        <business-letter-update-modal
            :person="person"
            ref="businessLetterUpdateModal"
        />

        <c-modal ref="touchReveal" :headline="getTouchHeadline(type)">
            <touch-form
                ref="touchForm"
                :person="person"
                :type="type"
                @save="closeAndReload"
                v-model="touch"
                :errors="touchErrors"
            />
        </c-modal>

        <content-header :headline="'Profil von ' + person.displayName">
        </content-header>
        <content-main>
            <div class="grid grid-cols-[5fr_7fr] gap-8">
                <div class="">
                    <div class="panel">
                        <div class="row align-middle">
                            <div class="columns">
                                <h1>{{ person.displayName }}</h1>
                            </div>
                            <div class="columns shrink">
                                <status-light :status="person.status" size="large"/>
                            </div>
                        </div>
                        <div>
                            <div class="grid grid-cols-2 justify-between grid-flow-col">
                                <strong>campus-Kundennummer: </strong>
                                <div v-if="person.customerNumber"
                                     class="text-right"
                                >
                                    {{ person.customerNumber }}
                                </div>
                            </div>
                            <div
                                v-if="person.previousCustomerNumbers"
                                class="grid grid-cols-2 justify-between grid-flow-col"
                            >
                                <strong>Alte campus-Kundennummern: </strong>
                                <div class="text-right">
                                    {{ person.previousCustomerNumbers.join(',') }}
                                </div>
                            </div>
                        </div>
                        <template v-if="touches.length === 0">
                            <p>
                                Datensatz am <strong>{{ person.createdAt|formatDate('LLL') }} Uhr</strong>
                                von <strong>{{ person.createdByDisplayName }}</strong>
                                erstellt.
                            </p>
                        </template>
                        <p v-else>
                            <strong>Letzter Kontakt</strong>: <span
                            style="float: right">{{ touches[0].date|formatDate('LLLL') }}</span>
                            <br>
                            <strong>Letzte Kontaktperson</strong>: <span
                            style="float: right">{{ touches[0].createdByDisplayName }}</span>
                        </p>

                        <div class="">
                            <h3 class="h-margin-bottom">Kontaktpunkt hinzufügen</h3>
                            <ul class="touchListAddBar">
                                <li>
                                    <a @click="addTouch('registration')"
                                       class="button small thirdly">Schnupper-Workshop</a>
                                </li>
                                <li>
                                    <router-link :to="'/touchComplex?create=note&personId=' + person.id"
                                                 class="button small thirdly">
                                        Notiz
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="'/touchComplex?create=call&personId=' + person.id"
                                                 class="button small thirdly">
                                        Anruf
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="'/touchComplex?create=email&personId=' + person.id"
                                                 class="button small thirdly">
                                        E-Mail
                                    </router-link>
                                </li>
                                <li>
                                    <a class="button small thirdly"
                                       @click="addTouchConsultation()">Beratungsgespräch</a>
                                </li>
                                <li>
                                    <router-link :to="'/touchComplex?create=firstContact&personId=' + person.id"
                                                 class="button small thirdly">Erstkontakt
                                    </router-link>
                                </li>
                                <li>
                                    <a class="button small thirdly" disabled="">Treffen</a>
                                </li>
                                <li>
                                    <a @click="addTouch('custom')"
                                       class="button small thirdly">
                                        Individueller Touch
                                    </a>
                                </li>
                                <li>
                                    <router-link :to="'/touchComplex?create=booking&personId=' + person.id"
                                                 class="button small thirdly">
                                        Rechnung (Einbuchung / Ausbuchung)
                                    </router-link>
                                </li>
                                <li>
                                    <a class="button small thirdly" disabled>Kataloganfrage</a>
                                </li>
                            </ul>
                        </div>

                        <div class="">
                            <h3 class="h-margin-bottom">Sonstige Aktionen</h3>
                            <ul class="touchListAddBar">
                                <li>
                                    <a
                                        @click="$refs.attendanceCertificationGeneratorUpdateModal.open()"
                                        class="button small thirdly"
                                    >
                                        Teilnahmebescheinigung erstellen
                                    </a>
                                </li>
                                <li>
                                    <a
                                        @click="$refs.studentIdUpdateModal.open()"
                                        class="button small thirdly"
                                    >
                                        Studentenausweis erstellen
                                    </a>
                                </li>
                                <li>
                                    <a
                                        @click="$refs.supportedContractAppearanceDocumentCreationModal.open()"
                                        class="button small thirdly"
                                    >
                                        Abwesenheit FÖBG
                                    </a>
                                </li>
                                <li>
                                    <a @click="$refs.businessLetterUpdateModal.open()"
                                       class="button small thirdly"
                                    >
                                        Geschäftsbrief erstellen
                                    </a>
                                </li>
                                <li>
                                    <a
                                        @click="$refs.documentModal.open(person)"
                                        class="button small thirdly"
                                    >
                                        Sonstiges Dokument erstellen
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="panel">
                        <form v-on:submit.prevent="submitPerson">
                            <person-form v-model="person" :errors="errors" size="small"/>

                            <div class="text-center">
                                <button type="submit" class="thirdly button">Aktualisieren</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="">
                    <ul class="tabs profile__tabs">
                        <li v-for="label, index in tabs">
                            <a @click="currentTab=index;" :class="{'--active': currentTab === index}">{{ label }}</a>
                        </li>
                    </ul>

                    <div class="contactList" v-if="currentTab === 'contactData'">
                        <contact-data :person="person"/>
                    </div>

                    <div v-if="currentTab === 'attachments'">
                        <attachments :entity-id="person['@id']"/>
                    </div>

                    <touch-list
                        v-if="currentTab === 'touches'"
                        :touches="filteredTouches"
                        :person="person"
                        v-on:deleteTouch="loadTouches"
                    />

                    <div class="touchList" v-if="currentTab === 'tasks'">
                        <task-table :person="person"/>
                    </div>

                    <div class="touchList" v-if="currentTab === 'timetable'">
                        <timetable :person="person"/>
                    </div>

                    <div class="touchList" v-if="currentTab === 'finance'">
                        <finance :person="person"/>
                    </div>

                    <div class="appearance" v-if="currentTab === 'appearance'">
                        <TouchAppearanceTable :person="person" v-if="person.hasOwnProperty('@id')"/>
                    </div>

                    <div class="absence" v-if="currentTab === 'absence'">
                        <AbsenceTable :person="person" v-if="person.hasOwnProperty('@id')"/>
                    </div>
                </div>
            </div>
        </content-main>
    </div>
</template>

<script>
    import PersonForm from "../../data/PersonForm";
    import TouchForm from "../../data/TouchForm";
    import Reveal from "../../components/Reveal";
    import util from "../../../plugins/util";
    import ContactData from "../../data/ContactData";
    import StatusLight from "../../data/Person/StatusLight";
    import TaskTable from "../../data/TaskTable";
    import TouchList from "../../data/Person/TouchList";
    import Attachments from "../../data/Person/Attachments";
    import Timetable from "../../data/Timetable";
    import Finance from "../../data/Person/Finance";
    import DataTable from "../../components/DataTable";
    import TouchAppearanceTable from "../../data/Appearance/Table";
    import AbsenceTable from '../../data/Absence/Table.vue';
    import AttendanceCertificationGeneratorUpdateModal
        from "../../data/AttendanceCertificationGenerator/UpdateModal.vue";
    import StudentIdUpdateModal from "../../data/StudentId/UpdateModal.vue";
    import DocumentCreationModal from "../../data/SupportedContractAppearanceReport/DocumentCreationModal.vue";
    import BusinessLetterUpdateModal from "@/data/BusinessLetter/UpdateModal.vue";
    import DocumentModal from "@/data/Person/DocumentModal.vue";

    export default {
        components: {
            DocumentModal,
            DocumentCreationModal,
            TouchAppearanceTable,
            DataTable,
            Finance,
            Timetable,
            AbsenceTable,
            Attachments,
            TouchList,
            TaskTable, StatusLight, ContactData, TouchForm, PersonForm, Reveal,
            AttendanceCertificationGeneratorUpdateModal,
            StudentIdUpdateModal,
            BusinessLetterUpdateModal,
        },
        data() {
            return {
                type: '',
                person: {},
                currentPerson: {},
                errors: {},
                touches: [],
                currentTab: 'touches',
                touch: {},
                touchErrors: {},
                tabs: {
                    touches: 'Touches',
                    contactData: 'Kontaktdaten',
                    tasks: 'ToDos',
                    attachments: 'Anhänge / Dateien',
                    timetable: '(Geplante) Modulteilnahmen',
                    finance: 'Finanzen',
                    appearance: 'Anwesenheiten & Checkliste zur Akte',
                    absence: 'Längere Abwesenheiten'
                }
            }
        },
        computed: {
            filteredTouches() {
                return this.touches.filter((item) => {
                    return item.clonedType !== 'reporting';
                })
            },
        },
        methods: {
            getTouchHeadline(type) {
                return window.global.touch.types[type];
            },
            addTouchConsultation() {
                if (this.person.lastTouchFirstContact) {
                    let _this = this;
                    this.$dialog
                        .confirm('Wie soll das Beratungsgespräch erstellt werden?', {
                            okText: 'Automatisch aus Erstkontakt befüllen',
                            cancelText: 'Leeres Formular',
                            customClass: 'large'
                        })
                        .then(function (dialog) {
                            _this.$router.push('/touchComplex?create=consultation&touchFirstContactId=' + _this.person.lastTouchFirstContact.id);
                        })
                        .catch(function () {
                            _this.$router.push('/touchComplex?create=consultation&personId=' + _this.person.id);
                        });
                } else {
                    this.$router.push('/touchComplex?personId=' + this.person.id + '&create=consultation');
                }
            },
            addTouch(type) {
                this.type = type;
                this.touch = {
                    person: this.person['@id']
                }
                this.$refs.touchReveal.open();
            },
            closeAndReload() {
                this.$refs.touchReveal.close();
                this.loadTouches();
            },
            async loadTouches() {
                this.touches = await this.$getAllFromApi(
                    'touches', {
                        person: this.$route.params.id,
                        order: {date: 'DESC'},
                        itemsPerPage: 10,
                    }
                );

            },
            loadPerson() {
                this.$getFromApi('people', this.$route.params.id, (entity) => {
                    this.person = entity;

                    if (
                        this.person.hasOwnProperty('duplicateRectifiedFrom')
                        && this.person.duplicateRectifiedFrom !== null
                    ) {
                        if (this.person.duplicateRectifiedFrom.hasOwnProperty('id')) {
                            this.$router.push('/persons/' + this.person.duplicateRectifiedFrom.id);
                        } else if (this.person.duplicateRectifiedFrom) {
                            this.$router.push('/persons/' + this.person.duplicateRectifiedFrom.slice(-36))
                        }
                        this.loadPerson();
                    }

                    this.currentPerson = Object.assign({}, entity);
                })
            },
            submitPerson() {
                this.errors = [];
                this.$sendToApi('people', this.person, (entity, status, errors) => {
                    if (status < 300) {
                        this.loadPerson();
                    } else {
                        this.errors = util.mapConstraints(errors);
                    }
                });
            },
            copyToClipboard(url) {
                const el = document.createElement('textarea');
                el.value = url;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                if (selected) {
                    document.getSelection().removeAllRanges();
                    document.getSelection().addRange(selected);
                }
            }
        },
        watch: {
            '$route': function () {
                this.loadPerson();
                this.touches = [];
                this.loadTouches();
            }
        },
        mounted() {
            this.loadTouches();
            this.touches = [];
            this.loadPerson();
        }
    }
</script>
