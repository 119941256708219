import Vue from "vue";

Vue.use({
  install(Vue) {
    Vue.prototype.$downloadCsv = function (dataArray, fileName) {
      console.log(dataArray);
      dataArray = dataArray.join('\r\n');

      let downloadLink = document.createElement("a");
      let blob = new Blob(["\ufeff", dataArray]);
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }
});

