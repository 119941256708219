<template>
	<div>
		<content-header
				headline="Anwesenheiten FÖBG"
		>
			<select v-model="month">
				<option
						v-for="(label, key) in monthOptions"
						:value="key"
				>
					{{ label }}
				</option>
			</select>
		</content-header>
		<content-main>
			<data-table
					endpoint="supported_contract_appearances"
					:columns="columns"
					:filters="filters"
					:filter-layout="filterLayout"
					:filter-large="true"
					:fixed-query="{month: month}"
					ref="dataTable"
					:disablePagination="true"
					updateRevealSize="large"
			>
				<template slot="dataRow" slot-scope="{singleData: supportedContractAppearance}">
					<td>
						{{ supportedContractAppearance.touchBooking.invoiceNumber }}
						<template v-if="supportedContractAppearance.touchBooking.start && supportedContractAppearance.touchBooking.end">
							<br>
							{{ supportedContractAppearance.touchBooking.start|formatDate('L') }} - {{ supportedContractAppearance.touchBooking.end|formatDate('L') }}
						</template>
					</td>
					<td>{{ supportedContractAppearance.person.displayName }}</td>
					<td>
						<template v-if="supportedContractAppearance.location">
							{{ supportedContractAppearance.location.name }}
						</template>
					</td>
					<td>
						<template v-if="supportedContractAppearance.supportedCostCenter">
							<router-link :to="'/crm/supportedCostCenters/' +  supportedContractAppearance.supportedCostCenter.id">
								{{ supportedContractAppearance.supportedCostCenter.name }}
								<template v-if="supportedContractAppearance.supportedCostCenter.email">
									<br>
									{{ supportedContractAppearance.supportedCostCenter.email }}
								</template>
							</router-link>
						</template>
					</td>
					<td>
						<template v-if="supportedContractAppearance.reportable">
							Ja
						</template>
					</td>
					<td>
						<ul class="list-none m-0 p-0">
							<li
								v-if="supportedContractAppearance.eventCourseDates === 0"
								class="text-warning whitespace-nowrap"
							>
								Keine geplante Anwesenheit in diesem Monat
							</li>
							<li
									v-if="supportedContractAppearance.missingAppearances > 0"
									class="text-alert whitespace-nowrap"
							>
								{{ supportedContractAppearance.missingAppearances }} Anwesenheiten fehlen
							</li>
							<li v-else class="text-green-500 whitespace-nowrap">
								Anwesenheiten gepflegt
							</li>
							<li class="text-warning whitespace-nowrap"
								v-if="supportedContractAppearance.attachment"
							>
								Dokument bereits erstellt
							</li>
							<li
									v-if="supportedContractAppearance.supportedCostCenter"
									class="text-green-500 whitespace-nowrap"
							>
								Kostenstelle vorhanden
							</li>
							<li
									v-else
									class="text-alert whitespace-nowrap"
							>
								Kostenstelle fehlt
							</li>
						</ul>
					</td>
					<td>
						<div class="grid gap-2">
							<router-link
									:to="'/persons/' + supportedContractAppearance.person.id"
									class="grid grid-flow-col justify-start gap-2"
							>
								<span class="action --show"></span>
								<span class="whitespace-nowrap">Person anzeigen</span>
							</router-link>
							<a
									v-if="supportedContractAppearance.attachment"
									@click="downloadDocument(supportedContractAppearance.attachment.id)"
									class="grid grid-flow-col justify-start gap-2"
							>
								<span class="action --download"></span>
								<span class="whitespace-nowrap">Dokument herunterladen</span>
							</a>
							<a
									v-if="supportedContractAppearance.supportedCostCenter && supportedContractAppearance.missingAppearances === 0"
									@click="createDocumentSupportAppearance(supportedContractAppearance)"
									class="grid grid-flow-col justify-start gap-2"
							>
								<span class="action --plus"></span>
								<span class="whitespace-nowrap">Dokument erstellen</span>
							</a>
						</div>
					</td>
				</template>
			</data-table>
		</content-main>
	</div>
</template>

<script>
    import moment from "moment";
    import DataTable from "../../../components/DataTable.vue";

    export default {
        components: {
            DataTable
        },
        methods: {
            reload() {
                this.$refs.dataTable.reload();
            },
            downloadDocument(attachmentId) {
                this.$http.get('attachments/' + attachmentId + '/download').then(response => {
                    window.location = response.data;
                });
            },
            createDocumentSupportAppearance(supportedContractAppearance) {
                this.$sendToApi(
                  'document/support_appearances',
                  {
                      touchBooking: supportedContractAppearance.touchBooking['@id'],
                      month: this.month
                  },
                  (entity, status, data) => {
                      supportedContractAppearance['attachment'] = entity['attachment'];
                      if (status < 300) {
                          this.downloadDocument(entity['attachment'].replace('/api/attachments/', ''));
                      } else {
                          this.$notify({
                              type: 'alert',
                              text: 'Fehler beim Erstellen des Dokuments'
                          });
                      }
                  }
                )
            }
        },
        watch: {
            month: function (newVal) {
				this.month = newVal;
				this.$nextTick(() => {
					this.$refs.dataTable.reload();
				})
            }
        },
        computed: {
            monthOptions() {
                let options = {};
                for (let m = moment('2020-01-01'); m.isBefore(); m.add(1, 'month')) {
                    options[moment(m).format('YYYY-MM')] = moment(m).format('MM-YYYY');
                }
                return options;
            },
            filters() {
                return [
                    {
                        type: 'text',
                        model: 'invoiceNumber',
                        label: 'Rechnungsnummer'
                    },
                    {
                        type: 'entity',
                        entity: 'location',
                        model: 'location',
                        label: 'Standort',
                        callback: function () {
                            return 'Ein Datensatz ausgewählt';
                        }
                    }
                ];
            },
            columns() {
                return {
                    invoiceNumber: {
                        text: 'Rechnungsnummer',
                        order: false,
                    },
                    person: {
                        text: 'Person',
                    },
                    location: {
                        text: 'Standort',
                    },
                    supportedContractAppearance: {
                        text: 'Kostenstelle',
                    },
					reportable: {
						text: 'Meldepflichtig',
					},
                    status: {
                        text: 'Status'
                    }
                };
            },
        },
        data() {
            return {
                month: moment().subtract(1, 'month').format('YYYY-MM'),
                duplicate: null,
                filterLayout: [
                    ['invoiceNumber'],
                    ['location'],
                ],
            }
        }
    }
</script>
