import Vue from 'vue';
import 'moment/locale/de';
import store from './store';
import moment from "moment";

Vue.filter('formatDate', function (value, format) {
    return moment(value).format(format);
});

Vue.filter('price', function (value) {
    let val = (value / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
});

Vue.filter('truncate', function(text, length, clamp){
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
});

Vue.filter('tSecurity', function (value) {
    return window.global.credibilities[value];
});

Vue.filter('tEducationType', function (value) {
    return window.global.education.types[value];
});

Vue.filter('tEventLocationType', function (value) {
    return window.global.event.locationTypes[value];
});

Vue.filter('tTouchType', function (value) {
    return window.global.touch.types[value];
});

Vue.filter('tPersonDuplicatesMatchKeys', function (value) {
    return window.global.personDuplicates.matchKeys[value];
});

Vue.filter('supportType', function (value) {
    switch (value) {
        case 'own': {
            return 'Selbstzahler';
        }
        case 'voucher': {
            return 'Gutschein';
        }
        case 'check': {
            return 'Bildungscheck';
        }
    }
});

Vue.filter('dataTrans', function (value, base) {
    var dataBase = store.getters.applicationSettings;
    base.split('.').forEach((item) => {
        dataBase = dataBase[item];
    });
    return dataBase[value];
});

Vue.prototype.$settingsObject = function (base) {
    var dataBase = store.getters.applicationSettings;
    base.split('.').forEach((item) => {
        dataBase = dataBase[item];
    });
    return dataBase;
}


Vue.prototype.$dataTranslate = function (value, base) {
    var dataBase = store.getters.applicationSettings;
    base.split('.').forEach((item) => {
        dataBase = dataBase[item];
    });
    return dataBase[value];
}

