<template>
	<div class="grid gap-x-8 grid-cols-6">
		<input-text
				class="col-span-6"
				label="Bezeichnung"
				v-model="value.name"
				:errors="errors.name"
		/>
		<input-text
				class="col-span-4"
				label="Zusätzliche Bezeichnung"
				v-model="value.additionalName"
				:errors="errors.additionalName"
		/>
		<div class="col-span-2">
			<label>
				Alle Daten reporten
				<br>
				<input type="checkbox" v-model="value.reportAllAppearances"/>
			</label>
		</div>
		<input-text
			class="col-span-3"
			label="Straße"
			v-model="value.street"
			:errors="errors.street"
		/>
		<input-text
			class="col-span-1"
			label="PLZ"
			v-model="value.zip"
			:errors="errors.zip"
		/>
		<input-text
			class="col-span-2"
			label="Stadt"
			v-model="value.city"
			:errors="errors.city"
		/>
		<input-text
			class="col-span-3"
			label="E-Mail"
			v-model="value.email"
			:errors="errors.email"
		/>
		<input-text
			class="col-span-3"
			label="Telefonnummer"
			v-model="value.phoneNumber"
			:errors="errors.phoneNumber"
		/>
	</div>
</template>

<script>
    import value from "../../../mixins/value";

    export default {
        mixins: [value]
    }
</script>
