<template>
    <div>
        <note-modal ref="noteModal" :show-create="false"/>

        <content-header
            headline="Anwesenheiten"
        />

        <content-main>
            <data-table
                endpoint="appearances"
                ref="dataTable"
                :columns="columns"
                :filters="filters"
                :pre-set-query="{accepted: 'null'}"
                :add-query-to-url="true"
            >
                <template slot="dataRow" slot-scope="{singleData: appearance}">
                    <td>
                        {{ appearance.date|formatDate('LL') }}
                        <br>
                        {{ appearance.durationUnits }}
                    </td>
                    <td>
                        <router-link :to="'/persons/' + appearance.person.id">
                            {{ appearance.person.displayName }}
                        </router-link>
                    </td>
                    <td>
                        {{ appearance.type|dataTrans('model.appearance.type') }}
                    </td>
                    <td>
                        <template v-if="appearance.accepted === true">
                            Ja
                        </template>
                        <template v-else-if="appearance.accepted === false">
                            Nein
                        </template>
                        <template v-else>
                            Ausstehend
                        </template>
                    </td>
                    <td>
                        <a @click="$refs.noteModal.open(appearance['@id'])">
                            Kommentare
                        </a>
                    </td>
                    <td>
                        <div
                            v-if="appearance.accepted === null"
                            class="grid gap-4"
                        >
                            <a

                                @click="setAccepted(appearance, true)"
                                class="underline hover:no-underline"
                            >
                                Anwesenheit akzeptieren
                            </a>
                            <a
                                @click="setAccepted(appearance, false)"
                                class="underline hover:no-underline"
                            >
                                Anwesenheit ablehnen
                            </a>
                        </div>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "@/components/DataTable.vue";
    import NoteModal from "@/data/NoteModal.vue";

    export default {
        components: {NoteModal, DataTable},
        props: {},
        methods: {
            setAccepted(appearance, status) {
                let _appearance = Object.assign({}, appearance);
                _appearance.accepted = status;
                this.$sendToApi('appearances', _appearance, (entity, status, data) => {
                    this.$refs.dataTable.reload();
                });
            }
        },
        computed: {
            filters() {
                return [
                    {
                        type: 'dateRange',
                        model: 'date',
                        label: 'Datum'
                    },
                    {
                        type: 'select',
                        model: 'accepted',
                        label: 'Status',
                        options: {
                            'null': 'Ausstehend',
                            1: 'Akzeptiert',
                            0: 'Nicht akzeptiert',
                        },
                        callback: function (item) {
                            if (item === 'null') {
                                return 'Ausstehend';
                            }
                            if (item === 1 || item === '1') {
                                return 'Akzeptiert';
                            }
                            if (item === 0 || item === '0') {
                                return 'Nicht Akzeptiert';
                            }
                            return '-';
                        }
                    },
                    {
                        type: 'type',
                        model: 'phoneNumber',
                        label: 'Telefonnummer'
                    },
                ];
            },
            columns() {
                return {
                    date: {
                        text: 'Datum',
                        order: true,
                    },
                    person: {
                        text: 'Person',
                    },
                    type: {
                        text: 'Typ',
                    },
                    accepted: {
                        text: 'Status',
                    },
                    notes: {
                        text: 'Kommentare/Notizen',
                    },
                };
            },
        }
    }
</script>
