<template>
    <label>
        <template v-if="label">{{label}}</template>
        <currency-input
            v-model="value"
            currency="EUR"
            locale="de"
            :aria-readonly="true"
        />
    </label>
</template>

<script>
    import value from "../../../mixins/value";

    export default {
        mixins: [value],
        props: {
            label: {

            }
        }
    }
</script>
