<template>
    <data-table
        endpoint="tasks"
        :state-key="this.person ? null : 'tasks'"
        :addQueryToUrl="!this.person"
        :columns="columns()"
        :filters="filter()"
        :pre-set-query="preSetQuery"
        :fixed-query="fixedQuery"
        ref="dataTable"
    >
        <template slot="dataRow" slot-scope="{singleData: task}">
            <td width="150px">
                {{ task.started|formatDate('ll') }}
            </td>
            <td>
                <strong>{{ task.type|dataTrans('model.task.types') }}</strong><br>
                <div v-if="task.followUpNote" v-html="task.followUpNote"></div>
            </td>
            <td>
                {{ task.status|dataTrans('model.task.stati') }}
            </td>
            <td>{{ task.assigned.displayName }}</td>
            <td v-if="!person">
                <router-link
                    v-if="task.person && task.person.hasOwnProperty('id')"
                    :to="'/persons/' + task.person.id"
                >
                    <status-light :status="task.person.status"/>
                    {{ task.person.displayName }}
                </router-link>
            </td>
            <td class="text-right">
                <router-link
                    :to="'/persons/' + task.person.id"
                    v-if="!person && task.person && task.person.hasOwnProperty('id')"
                    class="button small h-noMargin-bottom thirdly"
                >
                    Zur Person
                </router-link>
            </td>
        </template>
    </data-table>
</template>

<script>
    import DataTable from "../components/DataTable";
    import StatusLight from "../data/Person/StatusLight";
    import moment from 'moment';

    export default {
        props: {
            person: {
                type: Object,
                required: false,
                default: () => null
            }
        },
        components: {StatusLight, DataTable},
        computed: {
            fixedQuery() {
                if (this.person) {
                    return {
                        'triggeredTouch.person': this.person['id'],
                        'order[started]': 'asc',
                        /*'started': {
                            before: moment().format('YYYY-MM-DD')
                        }*/
                    }
                }

                return {}
            },
            preSetQuery() {
                var preSetQuery = {
                    'exists[releasedTouch]': 0,
                };

                if (!this.person) {
                    preSetQuery['assigned'] = this.$store.getters.getLoggedInUser['@id'];
                }

                return preSetQuery;
            },
        },
        methods: {
            filter() {
                let filter = [
                    {
                        type: 'select',
                        model: 'exists[releasedTouch]',
                        label: 'Anzeige',
                        disableNull: true,
                        options: {
                            1: 'Erledigte anzeigen',
                            0: 'Unerledigte anzeigen'
                        },
                        callback: function (value) {
                            if (parseInt(value) === 1) {
                                return 'Erledigte angezeigen';
                            } else {
                                return 'Unerledigte angezeigen';
                            }
                        }
                    },
                    {
                        type: 'dateRange',
                        model: 'started',
                        label: 'Fälligkeit'
                    },
                    {
                        label: 'Zugewiesen',
                        type: 'entity',
                        entity: 'user_real',
                        model: 'assigned',
                        callback: function (value) {
                            return 'Eine Person ausgewählt';
                        }
                    }
                ];

                if (this.person) {
                    filter['triggeredTouch.person'] = {
                        label: 'Person',
                        callback: function (value) {
                            return 'Aktuelle Person';
                        }
                    }
                }

                return filter;
            },
            columns() {
                let columns = {
                    started: {
                        text: 'Fällig seit',
                        order: true
                    },
                    type: {
                        text: 'ToDo-typ'
                    },
                    status: {
                        text: 'Status'
                    },
                    assigned: {
                        text: 'Zugewiesen',
                        filter: 'text'
                    }
                };

                if (!this.person) {
                    columns['person'] = {
                        text: 'Person'
                    }
                }
                return columns;
            }
        }
    }
</script>
